import React, {useMemo, forwardRef} from 'react';
import styled, {css} from 'styled-components';

import theme, {bp, color} from 'settings/theme';

import expandResponsiveValues from 'utils/expandResponsiveValues';


function getUnits(val){
  return val.replace(/\d+|^\s+|\s+$/g,'');
};

function removeUnits(val){
  return parseInt(val.replace(/\D/g,''));
};


// Calculate Sizing
function calculateSizing(props){


  let heights,
      corners,
      horizontalPadding,
      fontSizes;

  // Heights
  heights = props.height ? expandResponsiveValues(props.height, 6) : ['40px','40px','40px','50px','50px','50px'];


  // Corner Sizes
  if(props.corners){
    corners = expandResponsiveValues(props.corners, 6);
  }
  else{
    corners = heights.map((item) => {
      return (removeUnits(item) / 2) + getUnits(item)
    });
  }


  // Horizontal Padding
  horizontalPadding = props.horizontalPadding ? expandResponsiveValues(props.horizontalPadding, 6) : ['20px','20px','20px','30px','30px','30px'];

  // Font Sizes
  fontSizes = props.fontSize ? expandResponsiveValues(props.fontSize, 6) : ['14px','14px','14px','16px','16px','16px'];

  
  // Return sizing object
  const returnObj = {
    heights,
    corners,
    horizontalPadding,
    fontSizes
  };

  return  returnObj;

};


// Create media queries for different sizes
function createMediaQuery(props: any, bpNumber){

  let newCss =  css` @media (min-width: ${bp(bpNumber)}) {


    /* Has the height changed? */
    ${(props: any) => !props.autoHeight && props.sizing.heights[bpNumber-1] != props.sizing.heights[bpNumber-2] &&
      css`height: ${props.sizing.heights[bpNumber-1]};`
    }

    /* Has the corner radius changed? */
    ${(props: any) => props.sizing.corners[bpNumber-1] != props.sizing.corners[bpNumber-2] &&
      css`
        border-radius: ${props.sizing.corners[bpNumber-1]};
      `
    }

    /* Has the horizontal padding changed? */
    ${(props: any) => props.sizing.horizontalPadding[bpNumber-1] != props.sizing.horizontalPadding[bpNumber-2] &&
      css`
        padding-left: ${props.sizing.horizontalPadding[bpNumber-1]};
        padding-right: ${props.sizing.horizontalPadding[bpNumber-1]};
      `
    }

    /* Has the font size changed? */
    ${(props: any) => props.sizing.fontSizes[bpNumber-1] != props.sizing.fontSizes[bpNumber-2] &&
      css`
        font-size: ${props.sizing.fontSizes[bpNumber-1]};
      `
    }

  }`;

  return newCss;

}


const getRgbaColor = (col) => {

  const colors = {
    "blue": {default: color.blue(50), hover: color.blue(60)},
    "green": {default: color.green(50), hover: color.green(60)},
    "grey": {default: color.grey(50), hover: color.grey(60)},
    "light-grey": {default: color.grey(70), hover: color.grey(70)},
    "orange": {default: color.orange(50), hover: color.orange(60)},
    "white": {default: 'rgba(255, 255, 255, 1)', hover: 'rgba(255, 255, 255, 0.9)'}
  };

  return colors[col] || colors['grey'];

}


// Component
const Button = forwardRef((props: any, ref) => {

  const as = props.as || 'button';

  const sizing = calculateSizing(props);
  const backgroundColor = useMemo(() => getRgbaColor(props.color), [props.color]);
  

  return(

    <StyledButton  
      ref={ref}
      as={as}
      type={props.type}
      onClick={props.onClick}
      sizing={sizing}
      backgroundColor={backgroundColor}
      className={`button ${props.className ?? ''}`}
      disabled={props.disabled}
      href={props.href}
    >
      <React.Fragment>

        <div className="button_content">
          <span>
            {props.children}
          </span>
        </div>

      </React.Fragment>

    </StyledButton>
  );

})

Button.displayName = 'Button';

export default Button;


// Styled Component
const StyledButton = styled.button<any>`
    
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || 'auto'};
  height: ${props => !props.autoHeight ? props.sizing.heights[0] : 'auto'};
  padding: 0 ${props => props.sizing.horizontalPadding[0]};
  color: ${color.buttonText};
  font-size: ${props => props.sizing.fontSizes[0]};
  font-weight: bold;
  text-decoration: none;
  background-color: ${props => props.backgroundColor.default};
  transition: background-color 0.15s ease-out;
  -webkit-tap-highlight-color: transparent;

  &[disabled]{
    pointer-events: none;
    opacity: 0.3;
  }

  /* Corners */
  border-radius: ${props => props.sizing.corners[0]};


  /* Multi Line */
  ${props => (props.multiLine === undefined || props.multiLine === false) && 
    css`
      white-space: nowrap;
    `
  }


  /* Content */
  .button_content{
    display: flex;
    align-items: center;
  }


  /* Hover */
  &:hover{
    background-color: ${props => props.backgroundColor.hover};
  }

  ${props => createMediaQuery(props, 2)}
  ${props => createMediaQuery(props, 3)}
  ${props => createMediaQuery(props, 4)}
  ${props => createMediaQuery(props, 5)}
  ${props => createMediaQuery(props, 6)}
    
`;
