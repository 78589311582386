import React, {useMemo} from 'react'
import styled, {css} from 'styled-components'

import theme, {bp} from 'settings/theme'


type Props = {
  disabled?: boolean,
  fullWidthAt?: number[],
  className?: string,
  children?: React.ReactNode
};


// Component
const Component: React.FC<Props> = (props) => {

  const spacingClasses = useMemo(() => props.disabled ? `` : `paddingOuterGutters`, [props.disabled]);

	return(
    <StyledComponent {...props} className={`contentWrapper ${spacingClasses} ${props.className ? props.className : ''}`}>
      {props.children}
    </StyledComponent>
	);

}

Component.defaultProps = {
  disabled: false
} as Partial<Props>;


export default Component;


// Styles
const StyledComponent = styled.div<Props>`

  ${props => props.fullWidthAt &&
    css`

      ${props.fullWidthAt?.includes(1) &&
        css`
          @media (min-width: 0px) and (max-width: ${theme.breakpoints[1]-1}px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        `
      }

      ${props.fullWidthAt?.includes(2) &&
        css`
          @media (min-width: ${theme.breakpoints[1]}px) and (max-width: ${theme.breakpoints[2]-1}px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        `
      }

      ${props.fullWidthAt?.includes(3) &&
        css`
          @media (min-width: ${theme.breakpoints[2]}px) and (max-width: ${theme.breakpoints[3]-1}px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        `
      }

      ${props.fullWidthAt?.includes(4) &&
        css`
          @media (min-width: ${theme.breakpoints[3]}px) and (max-width: ${theme.breakpoints[4]-1}px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        `
      }

      ${props.fullWidthAt?.includes(5) &&
        css`
          @media (min-width: ${theme.breakpoints[4]}px) and (max-width: ${theme.breakpoints[5]-1}px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        `
      }


    `
  }

`
