import React, { useMemo } from 'react';
import styled, {css} from 'styled-components';
import SbEditable from 'components/SbEditableExtended';
import Link from 'next/link';

import ContentWrapper from 'components/contentWrapper';
import FakeWrapper from 'components/fakeWrapper';
import Button from 'components/button';

import theme, {bp} from 'settings/theme';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';
import multiOptionsToObject from 'components/blocks/utils/multiOptionsToObject';


type Props = {
  block: any,
  nested?: boolean
};

type StyledProps = {
  centered: boolean,
  stretchOnMobile?: boolean 
};

// Component
const Component: React.FC<Props> = (props) => {

  
  const displaySettings = useMemo(() => multiOptionsToObject(props.block?.display_settings), [props.block?.display_settings]);
  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing]);

  const OptionalPageWrapper = useMemo(() => {return props.nested ? FakeWrapper : FakeWrapper}, [props.nested]);
  const OptionalContentWrapper = useMemo(() => {return props.nested ? FakeWrapper : ContentWrapper}, [props.nested]);

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent 
        centered={displaySettings.centered} 
        stretchOnMobile={displaySettings.stretchOnMobile} 
        className={`blockButtonGroup ${spacingBottomClass}`}
      >

        <OptionalPageWrapper>
          <OptionalContentWrapper>
            <div className="blockButtonGroup-inner">

              {props.block.buttons.map((item: any, index: number) => (
                <div className="blockButtonGroup-item" key={index}>

                  {item.link.linktype === 'story' &&
                    <Link href={`/${item.link.cached_url}`} passHref>
                      <Button
                        corners="4px"
                        color={item.colour}
                      >
                        {item.text}
                      </Button>
                    </Link>
                  }

                  {item.link.linktype === 'url' &&
                    <Button
                      as="a"
                      href={item.link.url}
                      corners="4px"
                      color={item.colour}
                      target={item.open_in_new_tab === true ? '_blank' : '_self'}
                    >
                      {item.text}
                    </Button>
                  }
                    
                </div>
              ))}

            </div>
          </OptionalContentWrapper>
        </OptionalPageWrapper>

      </StyledComponent>
    </SbEditable>
	)

}


Component.defaultProps = {
  nested: false
} as Partial<Props>;


export default Component;


// Styles
const StyledComponent = styled.div<StyledProps>`

  .blockButtonGroup-inner{

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    ${props => props.centered === true && css`
      justify-content: center;
    `}

  }

  .blockButtonGroup-item{

    &:last-child{
      margin-bottom: 0;
    }

  }


  ${props => props.stretchOnMobile === true && css`
      
    @media (max-width: ${bp(2, true)}) {

      .blockButtonGroup-inner{

        .blockButtonGroup-item{

          display: block;
          width: 100%;

          a, button {
            width: 100%;
          }

        }

      }

    }


  `}

`