
// Expand Responsive Values
const expandResponsiveValues = (vals, len) => {

    // Takes an array of values and expands it to the correct length
    // E.g. [30,40] becomes [30,40,40,40,40,40] if the length is 6

    vals = Array.isArray(vals) ? vals : [vals]

    let newValues = [vals[0]]
    let newestVal = newValues[0]

    for (let i = 1; i < len; i++) {
        
        // If a value has been set and it is different to the last value
        if(vals[i] && vals[i] != newestVal){
            newValues[i] = vals[i]
            newestVal = vals[i]
        }
        else{
            newValues[i] = newestVal
        }

    }

    return newValues

}

export default expandResponsiveValues